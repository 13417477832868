import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Profesori = () => {
  const [status, setStatus] = useState(null);
  const [jsonData, setJsonData] = useState(null); // Starea pentru stocarea datelor primite de la API ca JSON

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.scovoicu.ro/profesori/', {
          auth: {
            username: 'transparenta',
            password: 'lahun2010'
          }
        });
        setStatus(response.status);
        setJsonData(response.data); 
      } catch (error) {
        console.error('Eroare:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
     <Helmet>
        <title>[Scovoicu Bacau] Cadre didactice</title>
        <meta name="description" content="Scoala Gimnaziala Octavian Voicu Bacau - Cadre didactice" />
        {/* Alte metadate */}
      </Helmet>
    <div className=" m-8 grid grid-cols-1 sm:grid sm:grid-cols-2 md:grid md:grid-cols-3 lg:grid lg:grid-cols-4 xl:grid xl:grid-cols-5">
     {jsonData && jsonData.persoane && jsonData.persoane.map((persoana, index) => (
        <div key={index} class="m-2 border p-4 rounded-lg shadow-md">
            <h2 className="text-md font-semibold text-center mb-2">
        {persoana.prenume} 
        <br/>
        {persoana.nume.toUpperCase()}
        </h2>
        <p className="text-md text-center mb-2">
            {persoana.functia}
        </p>
        </div>
      ))}
    </div>
    </>
  );
};

export default Profesori;
