'use client'
import { useState } from 'react';
import React from 'react'

import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'

export const Navbar = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const hidenMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-gray-100">
      <div className="max-auto px-4">
        <div className="flex justify-between">
          <div className="flex space-x-4">
            <div className="mr-4">
              <Link to="/" onClick={hidenMenu} className="flex items-center py-5 py-3 text-gray-700 hover:text-gray-900">
                <span className="font-bold pl-2 text-indigo-500">ScoVoicu [Bacau]</span>
              </Link>
            </div>
            <div className="hidden md:flex flex items-center space-x-1">
              <Link to="/profesori" className="py-2 px-3 text-gray-500 hover:bg-gray-700 hover:text-white block rounded-md px-2 py-1 text-base font-medium">Cadre Didactice</Link>
              <Link to="/burse" className="py-2 px-3 text-gray-500 hover:bg-gray-700 hover:text-white block rounded-md px-2 py-1 text-base font-medium">Burse</Link>
            </div>
          </div>
          <div className="hidden md:flex flex items-center space-x-1">
            <NavLink to="/documente" className="bg-indigo-400 text-white block rounded-md px-3 py-2 text-base font-medium hover:bg-gray-900" >Transparenta</NavLink>
          </div>
          <div className="md:hidden flex intems-center text-black">
            <button onClick={toggleMenu} className="">
              {isOpen ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
            </button>
          </div>
        </div>
      </div>
      <div className={`md:hidden ${isOpen ? 'block' : 'hidden'}`}>
        <div className="space-y-1 px-2 pb-3 pt-2">
          <Link to="/profesori" onClick={toggleMenu} className="text-gray-500 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Cadre Didactice</Link>
          <Link to="/burse" onClick={toggleMenu} className="text-gray-500 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Burse</Link>
          <Link to="/documente" onClick={toggleMenu} class="bg-indigo-400 text-white block rounded-md px-3 py-2 text-base font-medium" aria-current="page">Transparenta</Link>
        </div>
      </div>

    </nav>
  )
}
