import { Helmet } from 'react-helmet';

export default function Burse() {
  return (
    <>
      <Helmet>
        <title>Scovoicu Bacau</title>
        <meta name="description" content="Scoala Gimnaziala Octavian Voicu Bacau" />
        {/* Alte metadate */}
      </Helmet>

      <div className="m-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2">
        {[
          {
            href: "https://www.scovoicu.ro/documente/burse_ome_5518_2024",
            text: "Metodologie-Cadru <br /> de acordare a burselor",
          },
          {
            href: "https://www.scovoicu.ro/documente/BURSE_CERERE_BURSA_DE_MERIT_ART_7_2_LIT_A",
            text: "Bursa de merit <br /> (art. 7.2, lit. A)",
          },
          {
            href: "https://www.scovoicu.ro/documente/BURSE_CERERE_BURSA_DE_REZILIENTA_ART_7_3_LIT_3",
            text: "Bursa de reziliență <br /> (art. 7.3, lit. 3)",
          },
          {
            href: "https://www.scovoicu.ro/documente/BURSE_CERERE_BURSE_SOCIALE_MEDICAL_LIT_E",
            text: "Bursa socială <br /> Medical",
          },
          {
            href: "https://www.scovoicu.ro/documente/BURSE_CERERE_BURSE_SOCIALE_ORFANI_LIT_C",
            text: "Bursa socială <br /> Orfani",
          },
          {
            href: "https://www.scovoicu.ro/documente/BURSE_CERERE_SI_DECLARATIE_BURSA_SOCIALA_FAMILIE_MONOPARENTALA",
            text: "Bursa socială <br /> Familie monoparentală",
          },
          {
            href: "https://www.scovoicu.ro/documente/BURSE_CERERE_SI_DECLARATIE_BURSE_SOCIALE_VENITURI_MICI_LIT_A",
            text: "Bursa socială <br /> Venituri mici",
          },
        ].map(({ href, text }) => (
          <div key={href} className="m-2 border p-4 rounded-lg shadow-md">
            <a href={href} target="_blank" rel="noopener noreferrer">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-1 w-full">
                {text.split('<br />').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </button>
            </a>
          </div>
        ))}
      </div>

      <div className='bg-white rounded-lg m-11 w-auto'>
        <p className="mt-9 ml-8 mr-8 text-black text-base text-3xl font-bold text-center">
          <span class="text-blue-600 font-bold">Bursa de merit și reziliență</span>
        </p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">
          Până pe 3 octombrie, se pot depune la secretariat unității de învățământ documentele necesare pentru acordarea burselor.
          În termen de maxim 5 zile lucrătoare de la solicitare, după expirarea termenului de depunere, părinții/reprezentanții legali pot completa sau corecta dosarele incomplete sau incorecte, la solicitarea comisiei de management al burselor.
          Până la data de 20 octombrie a anului școlar în curs, școlile transmit prima listă de beneficiari din anul școlar în curs, pe tipuri de burse către ISJ.
          Până la data de 25 octombrie, ISJ transmite numărul de beneficiari pe jueț la Ministerul Educației.
        </p>
      </div>

      <div className='bg-white rounded-lg m-11 w-auto'>
        <p className="mt-9 ml-8 mr-8 text-black text-base text-3xl font-bold text-center">
          <span class="text-blue-600 font-bold">
            Calendar
          </span>
        </p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">
          Primesc bursă de merit elevii claselor a VI-a, a VII-a și a VIII-a cu medii generale pe anul școlar anterior mai mari sau egale cu 9,50. În situația în care prin aplicarea procentului de 30% nu sunt cuprinși toți elevii cu medii generale anuale mai mari sau egale cu 9,50, lista va fi extinsă pentru a-i cuprinde și pe aceștia.
          <br />
          Primesc bursă de reziliență elevii claselor a VI-a, a VII-a și a VIII-a care au obținut cel puțin media generală 7 în anul școlar anterior celui în care se acordă bursa, în ordinea descrescătoare a mediilor generale, respectând condiția: Numărul de elevi beneficiari ai bursei de merit acordate pe baza mediei, cumulat cu numărul de elevi beneficiari ai bursei de reziliență, acordată pe bază de medie, este 30% din numărul de elevi ai fiecărei clase de gimnaziu.
          <br />
          Prin exepție, în clasele în care elevii ce îndeplinesc criteriile de acordare a burselor de merit și reziliență nu reprezintă cumulat 30% din efectivul clasei, aceste burse se pot acorda și unui procent mai mic de 30%.
          <br />
          Bursa de merit și bursa de reziliență sunt acordate și sub condiția ca elevul beneficiar să fie promovat la toate disciplinele și să fi obținut media 10 la purtare sau calificativul "foarte bine" la purtare la finalul anului școlar anterior.
          <br />
          Pentru elevii de clasa a V-a, listele se întocmesc în primele 15 zile ale anului următor, în ordinea descrescătoare a mediilor calculate ca medie aritmetică, având două zecimale, fără rotunjire, a notelor obținute de elevi la toate disciplinele pe parcursul primelor două intervale de învățare din anul școlar în curs. În situația în care prin aplicarea procentului de 30% nu sunt cuprinși toți elevii cu medii mai mari sau egale cu 9,50, lista va fi extinsă pentru a-i cuprinde și pe aceștia.
          <br />
          Bursele de merit și reziliență se acordă elevilor pe baza rezultatelor obținute conform prevederilor art.7 alin. (1) lit. a)-c) la propunerea dirigintelui, nefiind condiționate de depunerea unei cereri în acest sens. Profesorul diriginte va înainta la secretariatul unității de învățământ lista elevii propuși pentru bursa de merit pe baza mediei generale, în primele 25 zile calendaristice de la începerea cursurilor anului școalar, iar pentru elevii de clasa a V-a, în primele 15 zile calendaristice ale lunii ianuarie.
          <br />
          Bursele se acordă în fiecare an școlar, pe perioada cursurilor școlar, inclusiv pe timpul pregătirii și susținerii evaluării naționale de către elevii clasei a VIII-a, a examenului național de bacalaureat, a examenului de certificare a calificării profesionale și pe perioada pregătirii practice.
          <br />
          Elevii care acumulează 10 sau mai multe absențe nemotivate într-o lună nu primesc bursă de merit sau reziliență pentru luna respectivă.
        </p>
      </div>
      <div className='bg-white rounded-lg m-11 w-auto'>
        <p className="mt-9 ml-8 mr-8 text-black text-base text-3xl font-bold text-center">
          <span class="text-blue-600 font-bold">
            Bursa socială
            <br />
            se acordă la cerere luând în considerare nivelul venitului mediu pe membru de familie
          </span>
        </p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">
          <ul class="list-disc">
            <li>
              Se acordă elevilor proveniți din familii care realizează un venit mediu net lunar pe membru de familie, supus impozitului pe venit, pe ultimele 12 luni anterioare cererii, mai mic de 50% din salariul minim pe economie.
            </li>
            <li>
              Se acordă elevilor din familii monoparentale care realizează un venit mediu net lunar pe membru de familie, supus impozitului pe venit, pe ultimele 12 luni anterioare cererii, mai mic de un salariu minim net pe economie.
            </li>
            <li>
              Se va lua în calcul salariul minim pe economie în vigoare la data depunerii cererii
            </li>
            <li>
              Elevii care acumulează 10 sau mai multe absențe nemotivate într-o lună nu primesc bursa socială pentru luna respectivă
            </li>
            <li>
              Bursa socială se poate cumula cu bursa merit și/sau bursa de reziliență
            </li>
            <li>
              Un elev poate beneficia de o singură bursă socială, din bugetul Ministerului Educației, chiar dacă îndeplinește criteriile prevăzute la mai multe litere ale art. 10 alin. (1) din OME 5518/2024
            </li>
          </ul>
          <br />
          Documente care vor fi depuse în termen de 25 zile calendaristice de la începerea cursurilor anului școlar, de părinți/ reprezentanții legali/ elevii majori pentru acordarea burselor sociale:
          <ul class="list-disc">
            <li>
              cererea părintelui/ reprezentantului legal/ elevului major
            </li>
            <li>
              declarație pe proprie răspundere privind veniturile nete, cu caracter permanent, obținute pe ultimele 12 luni anterioare cererii, realizate de membrii familiei (supuse impozitării) și acordul privind prelucrarea datelor cu caracter personal pentru verificarea respectării criteriilor de acordare a bursei
            </li>
            <li>
              documente doveditoare ale componenței familiei, așa cum este definită la art. 5: "certificatele de naștere ale copiilor sub 14 ani, actele de identitate ale persoanelor care au peste 14 ani, acte referitoare la starea civilă de la momentul depunerii cererii, certificatul de divorț și convenția notarială încheiată în cadrul procesului de divorț cu copii minori, sentință judecătorească din care să rezulte stabilirea domiciliului copilului/ copiilor la unul dintre părinți, certificat de deces, decizia instanței de menținere a stării de arest, raport de anchetă socială în cazul părinților dispăruți (după caz)"
            </li>
            <li>
              adeverințe de venit net de la locul de muncă al părinților din care să reiasă venitul net (supus impozitului pe venit) pe ultimele 12 luni (salariul net + tichete de masă + tichete de vacanță) pentru perioada septembrie 2023 - august 2024
            </li>
            <li>
              adeverințe de venit de la administrația financiară pentru perioada specificată pentru părinții și copii între 18-26 ani care frecventează o unitate de învățământ, pe anul 2023 și 2024
            </li>
            <li>
              adeverință elev/student pentru copiii cu vârsta între 18-26 ani care frecventează o unitate de învățământ
            </li>
          </ul>
        </p>
      </div>

      <div className='bg-white rounded-lg m-11 w-auto'>
        <p className="mt-9 ml-8 mr-8 text-black text-base text-3xl font-bold text-center">
          <span class="text-blue-600 font-bold">
            Bursa socială
            <br />
            se acordă la cerere fără a se lua în considerare nivelul venitului mediu pe membru de familie
          </span>
        </p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">
          <ul>
            <li>
              Se acordă elevilor cu unul sau ambii părinți decedați
            </li>
            <li>
              elevilor asupra cărora a fost instituită o măsură de protecție specială, respectiv plasamentul/ plasamentul de urgență, elevilor care au deficiențe/ afectări funcționale produse de boli, tulburări sau afecțiuni ale structurilor și funcțiilor organismului, încadrate conform criteriilor din anexa nr. 1 la Ordinul Ministrului Sănătății și al Ministrului Muncii nr. 1306/1883/2016 pentru aprobarea criteriilor biopsihosociale de încadrare a copiilor cu dizabilități în grad de handicap și a modalităților de aplicare a acestora, cu modificările și completările ulterioare, și structurate tipologic conform aceluiași ordin
            </li>
          </ul>
          <br />
          Elevii care acumulează 10 sau mai multe absențe nemotivate într-o lună nu primesc bursa socială pentru luna respectivă. Bursa socială se poate cumula cu bursa de merit sau bursa de rezidență. Un elev poate beneficia de o singură bursă socială, din bugetul Ministerului Educației chiar dacă îndeplinește criteriile prevăzute la mai multe litere ale art. 10, alin. (1) din OME 5518/2024
          <br />
          Documente care vor fi depuse în termen de 25 zile calendaristice de la începerea cursurilor anului școlar, de părinți/ reprezentanți legali/ elevi majori pentru acordarea burselor sociale:
          <ul>
            <li>
              cererea părintelui/ reprezentantului legal/ elevului major
            </li>
            <li>
              documente doveditoare ale componenței familiei așa cum este definită la articolul 5; certificatele de naștere ale copiilor sub 14 ani; actele de identitate ale persoanelor care au peste 14 ani
            </li>
            <li>
              documente din care să reiasă încadrarea elevului în unul din următoarele criterii:
              <br />
              - orfan (se solicită certificat de deces al părintelui)
              <br />
              - elev asupra cărora a fost instituită o măsură de protecție specială, respectiv plasamentul/ plasamentul de urgență (se solicită hotărârea judecătorească)
              <br />
              - elevi care au deficiențe/ afectări funcționale produse de boli (se solicită certificatul de încadrare în grad de handicap și/sau certificatul eliberat de medicul specalist - tip A5 - cu luarea în evidență de către medicul de familie)
            </li>
          </ul>
        </p>
      </div>

      <div className='bg-white rounded-lg m-11 w-auto'>
        <p className="mt-9 ml-8 mr-8 text-black text-base text-3xl font-bold text-center">
          <span class="text-blue-600 font-bold">
            Familia
          </span>
        </p>
        <p className="ml-2 mr-2 text-black text-xs text-justify">
          <ul>
            <li>
              (1) Prin "familie" se înțelege soțul și soția cu copiii lor necăsătoriți, aflați în întreținere, cu vârsta până la 18 ani sau de până la 26 ani pentru cei care urmează o formă de învățământ - cursuri de zi, organizată potrivit legii, cu domiciliul ori reședința comună înscrisă în actele de identitate și care locuiesc împreună
            </li>
            <li>
              (2) Se asimilează termenului "familie" bărbatul și femeia necăsătoriți, cu copiii lor necăsătoriți și/sau ai fiecăruia dintre ei, având vârsta de peste 18 ani sau de până la 26 ani dacă urmează o formă de învățământ - cursuri de zi, organizată potrivit legii, care locuiesc împreună, dacă această situație se confirmă în teren
            </li>
            <li>
              (3) Sintagma "familie monoparentală" desemnează familia formată din persoana singură și copilul/copiii aflat/aflați în întreținere, în vârstă de până la 18 ani sau până la 26 ani pentru cei care urmează o formă de învățământ - cursuri de zi, organizată potrivit legii, și care locuiesc împreună
            </li>
            <li>
              (4) Prin "persoană singură din familia monoparentală" se înțelege persoana care se află în una dintre următoarele situații:
              a) este necăsătorită <br />
              b) este văduvă <br />
              c) este divorțată <br />
              d) al/a cărei soț/soție este declarat/declarată dispărut/dispărută prin hotărâre judecătorească <br />
              e) al/a cărei soț/soție se află în una din situațiile prevăzute la art. 178 lit. c) sau d) din Legea Nr. 287/2009 privind Codul civil, republicată, cu modificările și completările ulterioare <br />
              f) al/a cărei soț/soție este arestat/arestată preventiv pe o perioadă mai mare de 30 de zile sau execută o pedeapsă privativă de libertate și nu participă la întreținerea copiilor <br />
              g) a fost numită tutore sau i s-au încredințat ori dat în plasament unul sau mai mulți copii și se află în una dintre situațiile prevăzute la lit. a)-e)
            </li>
            <li>
              (5) Se asimilează termenului de "familie" și persoana/persoanele care se ocupă de întreținerea copilului pe perioada absenței părinților sau tutorelui, conform art. 104 din Legea 272/2004 privind protecția și promovarea drepturilor copilului, republicată, cu modificările și completările ulterioare, și care locuiește/locuiesc împreună cu copiii aflați în întreținrea sa/lor
            </li>
          </ul>
        </p>
      </div>

      <br /><br /><br /><br />
    </>
  );
}