import React from 'react'
import { Routes, Route } from 'react-router-dom'


import Dashboard from './pages/dashboard';
import DocumenteLista from './components/Documente';
import { Navbar } from './components/Navbar';
import ViewPDF from './components/ViewPDF';
import Profesori from './pages/profesori';

import Burse from './pages/burse';

export default function App() {



  return (
    <>
      <Navbar />
      <Routes>
        <Route path='/' element={<Dashboard />} />
        <Route path='/documente' element={<DocumenteLista />} />
        <Route path='documente/:fisier' element={<ViewPDF />} />
        <Route path='profesori' element={<Profesori />} />
        <Route path='burse' element={<Burse />} />
      </Routes>
    </>
  );
}
