import React from 'react'
import { Helmet } from 'react-helmet';

import { NavLink } from 'react-router-dom'

const documente = [
  { title: '[Rechizite] Cerere', date: '10 Septembrie 2024', file: 'rechizite_cerere', id: 52 },
  { title: '[Rechizite] Precizari', date: '10 Septembrie 2024', file: 'rechizite_precizari', id: 51 },
  { title: '[Tichete sociale] Cerere', date: '10 Septembrie 2024', file: 'tichete_sociale_cerere', id: 50 },
  { title: '[Tichete sociale] Precizari', date: '10 Septembrie 2024', file: 'tichete_sociale_precizari', id: 49 },
  { title: 'Burse Metodologie Cadru OME 5518-2024', date: '10 Septembrie 2024', file: 'burse_ome_5518_2024', id: 48 },
  { title: 'Hotarare CA 48', date: '09 Martie 2021', file: 'ovhca-48-09-03-2021', id: 47 },
  { title: 'Hotarare CA 47', date: '09 Martie 2021', file: 'ovhca-47-09-03-2021', id: 46 },
  { title: 'Hotarare CA 46', date: '25 Februarie 2021', file: 'ovhca-46-25-02-2021', id: 45 },
  { title: 'Hotarare CA 45', date: '25 Februarie 2021', file: 'ovhca-45-25-02-2021', id: 44 },
  { title: 'Hotarare CA 44', date: '25 Februarie 2021', file: 'ovhca-44-25-02-2021', id: 43 },
  { title: 'Hotarare CA 43', date: '05 Februarie 2021', file: 'ovhca-43-05-02-2021', id: 42 },
  { title: 'Hotarare CA 42', date: '05 Februarie 2021', file: 'ovhca-42-05-02-2021', id: 41 },
  { title: 'Hotarare CA 41', date: '05 Februarie 2021', file: 'ovhca-41-05-02-2021', id: 40 },
  { title: 'Hotarare CA 40', date: '29 Ianuarie 2021', file: 'ovhca-40-29-01-2021', id: 39 },
  { title: 'Hotarare CA 39', date: '29 Ianuarie 2021', file: 'ovhca-39-29-01-2021', id: 38 },
  { title: 'Hotarare CA 38', date: '29 Ianuarie 2021', file: 'ovhca-38-29-01-2021', id: 37 },
  { title: 'Hotarare CA 37', date: '29 Ianuarie 2021', file: 'ovhca-37-29-01-2021', id: 36 },
  { title: 'Hotarare CA 36', date: '29 Ianuarie 2021', file: 'ovhca-36-29-01-2021', id: 35 },
  { title: 'Hotarare CA 35', date: '22 Decembrie 2020', file: 'ovhca-35-22-12-2020', id: 34 },
  { title: 'Hotarare CA 34', date: '22 Decembrie 2020', file: 'ovhca-34-22-12-2020', id: 33 },
  { title: 'Hotarare CA 33', date: '22 Decembrie 2020', file: 'ovhca-33-22-12-2020', id: 32 },
  { title: 'Hotarare CA 32', date: '22 Decembrie 2020', file: 'ovhca-32-22-12-2020', id: 31 },
  { title: 'Hotarare CA 31', date: '09 Octombrie 2020', file: 'ovhca-31-09-10-2020', id: 30 },
  { title: 'Hotarare CA 30', date: '09 Octombrie 2021', file: 'ovhca-30-09-10-2020', id: 29 },
  { title: 'Hotarare CA 29', date: '22 Septembrie 2020', file: 'ovhca-29-22-09-2020', id: 28 },
  { title: 'Hotarare CA 28', date: '22 Septembrie 2020', file: 'ovhca-28-22-09-2020', id: 27 },
  { title: 'Hotarare CA 27', date: '15 Septembrie 2020', file: 'ovhca-27-15-09-2020', id: 26 },
  { title: 'Hotarare CA 26', date: '15 Septembrie 2020', file: 'ovhca-26-15-09-2020', id: 25 },
  { title: 'Hotarare CA 25', date: '09 Septembrie 2020', file: 'ovhca-25-09-09-2020', id: 24 },
  { title: 'Hotarare CA 24', date: '09 Septembrie 2020', file: 'ovhca-24-09-09-2020', id: 23 },
  { title: 'Hotarare CA 23', date: '09 Septembrie 2020', file: 'ovhca-23-09-09-2020', id: 22 },
  { title: 'Hotarare CA 22', date: '08 Septembrie 2020', file: 'ovhca-22-08-09-2020', id: 21 },
  { title: 'Hotarare CA 21', date: '08 Septembrie 2020', file: 'ovhca-21-08-09-2020', id: 20 },
  { title: 'Hotarare CA 13', date: '01 Septembrie 2020', file: 'ovhca-13-01-09-2020', id: 19 },
  { title: 'Hotarare CA 12', date: '01 Septembrie 2020', file: 'ovhca-12-01-09-2020', id: 18 },
  { title: 'Hotarare CA 11', date: '01 Septembrie 2020', file: 'ovhca-11-01-09-2020', id: 17 },
  { title: 'Hotarare CA 10', date: '01 Septembrie 2020', file: 'ovhca-10-01-09-2020', id: 16 },
  { title: 'Hotarare CA 09', date: '01 Septembrie 2020', file: 'ovhca-09-01-09-2020', id: 15 },
  { title: 'Hotarare CA 08', date: '01 Septembrie 2020', file: 'ovhca-08-01-09-2020', id: 14 },
  { title: 'Hotarare CA 07', date: '01 Septembrie 2020', file: 'ovhca-07-01-09-2020', id: 13 },
  { title: 'Hotarare CA 06', date: '01 Septembrie 2020', file: 'ovhca-06-01-09-2020', id: 12 },
  { title: 'Hotarare CA 05', date: '01 Septembrie 2020', file: 'ovhca-05-01-09-2020', id: 11 },
  { title: 'Hotarare CA 04', date: '01 Septembrie 2020', file: 'ovhca-04-01-09-2020', id: 10 },
  { title: 'Hotarare CA 03', date: '01 Septembrie 2020', file: 'ovhca-03-01-09-2020', id: 9 },
  { title: 'Hotarare CA 02', date: '01 Septembrie 2020', file: 'ovhca-02-01-09-2020', id: 8 },
  { title: 'Hotarare CA 01', date: '01 Septembrie 2020', file: 'ovhca-01-01-09-2020', id: 7 },
  { title: 'Executie OV 2020', date: '01 Septembrie 2020', file: 'ovexecutie-2020', id: 6 },
  { title: 'Executie OV 2019', file: 'ovexecutie-2019', id: 5 },
  { title: 'Executie OV 2018', file: 'ovexecutie-2018', id: 4 },
  { title: 'Buget OV 2020', file: 'ovbuget-2020', id: 3 },
  { title: 'Buget OV 2019', file: 'ovbuget-2019', id: 2 },
  { title: 'Buget OV 2018', file: 'ovbuget-2018', id: 1 },
  { title: 'Regulament OV', file: 'RegulamentOV', id: 0 },
];


export default function DocumenteLista() {
  const listItems = documente.map(document =>
    <div class="m-2 border p-4 rounded-lg shadow-md"
      key={document.id}
    >
      <h2 class="text-md font-semibold text-center mb-2">
        {document.title}
      </h2>
      <p class="text-md text-center mb-2">{document.date}</p>
      <p class="bg-indigo-400 text-white block rounded-md px-3 py-2 text-base text-center font-medium">
        <NavLink to={`/documente/${document.file}`}>Vizulalizare PDF</NavLink>
      </p>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>[Scovoicu Bacau] Transparenta</title>
        <meta name="description" content="Scoala Gimnaziala Octavian Voicu Bacau - Transparenta" />
        {/* Alte metadate */}
      </Helmet>
      <div class=" m-8 grid grid-cols-1 sm:grid sm:grid-cols-2 md:grid md:grid-cols-3 lg:grid lg:grid-cols-4 xl:grid xl:grid-cols-5">
        {listItems}
      </div>
      <br /><br /><br />
    </>
  );
}